// import { tns } from "tiny-slider"
// import Splide from '@splidejs/splide';

const mobileBreackpoint = 1080

var isMobile = window.innerWidth < mobileBreackpoint

function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

function getCookie(cname) {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      let result = c.substring(name.length, c.length)
      if (result) {
        return JSON.parse(result)
      }
      // return c.substring(name.length, c.length);
    }
  }
  return ''
}

document.addEventListener('DOMContentLoaded', () => {
  const agreement = document.getElementById('agreement')

  agreement?.addEventListener('change', e => {
    const isSelected = e.target.checked
    document.querySelector('.reach-out-form input[type="submit"]').disabled = !isSelected
  })

  // Cookies
  let cookies = getCookie('CookiesConsent')

  if (!cookies?.consent) {
    document.querySelector('.cookies-banner').classList.add('show')
    document.querySelector('.cookies-banner .close').addEventListener('click', () => {
      document.querySelector('.cookies-banner').classList.remove('show')
      setCookie('CookiesConsent', JSON.stringify({ consent: true }), 365)
    })

    document.querySelector('.cookies-banner .allow-link').addEventListener('click', () => {
      document.querySelector('.cookies-banner').classList.remove('show')
      setCookie('CookiesConsent', JSON.stringify({ consent: true }), 365)
    })
  }

  // Switch boxes
  const switchBoxesContainer = document.querySelector('.switch-boxes')
  const switchBoxesItems = switchBoxesContainer?.querySelectorAll('.switch-box-item')

  switchBoxesItems?.forEach(element => {
    element.addEventListener('click', () => {
      switchBoxesContainer.querySelector('.switch-box-item.active')?.classList.remove('active')
      element.classList.add('active')
    })
  })

  if (switchBoxesItems) {
    const interval = setInterval(() => {
      const nextElement =
        switchBoxesContainer.querySelector('.switch-box-item.active + .switch-box-item') ||
        switchBoxesContainer.querySelector('.switch-box-item:nth-child(1)')
      nextElement.click()
    }, 5000)
  }

  // Mobile menu
  const mobileMenuButtons = document.querySelectorAll('.burger-mobile-menu')

  mobileMenuButtons.forEach(button => {
    button.addEventListener('click', () => {
      const navMenu = document.querySelector('.mobile-menu')
      document.body.classList.toggle('mobile-menu-open')
      navMenu.classList.toggle('show')
    })
  })

  // select the open-btn button
  let reachOutBtns = document.querySelectorAll('[data-modal-btn="reach-out"]')
  let modalReachOut = document.querySelector('[data-modal="reach-out"]')
  let closeBtns = modalReachOut.querySelectorAll('[data-btn="close"]')

  // modalReachOut.classList.add('success');
  // modalReachOut.classList.add('error');

  closeBtns.forEach(closeBtn => {
    closeBtn.addEventListener('click', function (e) {
      e.preventDefault()
      modalReachOut.style.display = 'none'
      document.body.classList.remove('scroll-block')
    })
  })

  reachOutBtns.forEach(openBtn => {
    openBtn.addEventListener('click', function () {
      modalReachOut.style.display = 'block'
      document.body.classList.add('scroll-block')
    })
  })

  // Running line
  $(function () {
    $('.running-line-slider').slick({
      autoplay: true,
      autoplaySpeed: 0,
      speed: 5000,
      arrows: false,
      swipe: false,
      slidesToShow: 8,
      cssEase: 'linear',
      pauseOnFocus: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 8
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    })

    $('.hits-slider').slick({
      infinite: true,
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1
    })
  })

  window.addEventListener('resize', () => {
    isMobile = window.innerWidth < 1080
    const navMenu = document.querySelector('.mobile-menu')

    if (!isMobile) {
      navMenu.classList.remove('show')
      document.body.classList.remove('mobile-menu-open')
    }
  })
})
